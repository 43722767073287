import { Routes } from '@angular/router';

import { authGuard } from './guards/auth.guard';
import { authReverseGuard } from './guards/auth-reverse.guard';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./components/landing/landing.component').then(m => m.LandingComponent),
  },
  {
    path: 'find-your-api-keys',
    loadComponent: () => import ('./components/dashboard/find-api-keys/find-apikeys.component').then(m => m.FindApiKeysComponent),
  },
  {
    path: 'faq',
    loadComponent: () => import ('./components/faq/faq.component').then(m => m.FaqComponent),
  },
  {
    path: 'role-response/:code',
    loadComponent: () => import ('./components/roles/role-response.component').then(m => m.RoleResponseComponent)
  },
  {
    path: 'forgot-password',
    canActivate: [ authReverseGuard() ],
    loadComponent: () => import('./components/auth/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent)
  },
  {
    path: 'reset-password',
    canActivate: [ authReverseGuard() ],
    loadComponent: () => import('./components/auth/reset-password/reset-password.component').then(m => m.ResetPasswordComponent)
  },
  {
    path: '',
    canActivate: [ authGuard() ],
    children: [
      {
        path: 'dashboard',
        loadComponent: () => import('./components/dashboard/dashboard.component').then(m => m.DashboardComponent),
      },
      {
        path: 'dashboard/:status',
        loadComponent: () => import('./components/dashboard/dashboard.component').then(m => m.DashboardComponent),
      },
      {
        path: 'tournaments',
        loadComponent: () => import('./components/tournaments/tournament.component').then(m => m.TournamentComponent),
      },
      {
        path: 'tournaments/:title',
        loadComponent: () => import('./components/tournaments/single-view/single-view-tournament.component').then(m => m.SingleViewTournamentComponent),
      },
      {
        path: 'profile-edit',
        loadComponent: () => import ('./components/profile-edit/profile-edit.component').then(m => m.ProfileEditComponent),
      }
    ]
  },
  {
    path: 'user/:id',
    canActivate: [ authGuard(true) ],
    loadComponent: () => import('./components/admin/users/users.component').then(m => m.UsersComponent),
    data: { pageName: 'User' },
  },
  {
    path: 'admin-panel',
    canActivate: [ authGuard(true) ],
    loadComponent: () => import('./components/admin/panel/admin-panel.component').then(m => m.AdminPanelComponent),
  }
];

export class AppRoutingModule { }
