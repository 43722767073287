import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { UserService } from './../services/user-service';

export const authReverseGuard = () => {
  return async () => {
    const router = inject(Router);
    const userService = inject(UserService);

    const isLogged = userService.isUserLogged();

    if (isLogged) {
      const user = userService.user();

      if (!user) {
        const responseStatus = await userService.setUserFromDatabase();

        if (responseStatus !== 200) {
          return router.navigate([ '/' ]);
        }
      }

      return router.navigate([ '/dashboard' ]);
    }

    return true;
  }
}
