import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, catchError, map, of } from 'rxjs';

import { XSSService } from './../xss.service';
import { HttpService, CustomResponse } from './../http.service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class RequestsCommonService {

  constructor(
    private httpClient: HttpClient,
    private xSSService: XSSService,
    private httpService: HttpService) { }

  public checkForMaintenance(): Observable<number> {
    const url = environment.apiUrl + environment.endpoints.common.maintenance;

    return this.httpClient.get(url)
      .pipe(
        map(() => {
          return 200;
        }),
        catchError(() => {
          return of(503);
        })
      );
  }

}
